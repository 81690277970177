import React, { Component } from "react";

// External imports
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";

// Component imports
import { login } from "../../../../../actions/AuthAction";
import { resetLoader } from "../../../../../actions/AppAction";
import { RESET_LOADER } from "../../../../../actions/Type";
import { EMAIL, EMAIL_WRONG_FORMAT, LOGIN, PASSWORD, PASSWORD_REQUIRED, PASSWORD_TOO_LONG, PASSWORD_TOO_SHORT, USER_NAME_REQUIRED } from "../../../../../config/app_strings";

class LoginForm extends Component {
  // component will unmount
  componentWillUnmount() {
    // function called when the user leaves this page
    this.props.resetLoader(RESET_LOADER);
  }

  render() {
    const { isLoading, login, isLoggedIn } = this.props;

    // Yup validation schema
    const loginFormValidationSchema = Yup.object().shape({
      userUsername: Yup.string()
        .required(USER_NAME_REQUIRED)
        .email(EMAIL_WRONG_FORMAT),
      userPassword: Yup.string()
        .required(PASSWORD_REQUIRED)
        .min(2, PASSWORD_TOO_SHORT)
        .max(15, PASSWORD_TOO_LONG),
    });

    //   Submitting login form data
    const submittingLogin = async (values) => {
      await login(values.userUsername, values.userPassword);
    };

    if (localStorage.getItem("login_role_id") === "1") {
      return <Navigate to="/dashboard" />;
    }

    if (isLoggedIn) {
      if (localStorage.getItem("login_role_id") === "1")
        return <Navigate to="/dashboard" />;
    }

    return (
      <Formik
        initialValues={{
          userUsername: "",
          userPassword: "",
        }}
        validationSchema={loginFormValidationSchema}
        onSubmit={(values) => submittingLogin(values)}
      >
        {({ errors, touched, values, resetForm }) => (
          <Form className="flex flex-col w-full px-10 pt-10 pb-5 space-y-4">
            {/* Username */}
            <div className="flex flex-col justify-start space-y-3">
              <Field
                name="userUsername"
                id="userUsername"
                type="email"
                placeholder={EMAIL}
                className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50"
              />
              {errors.userUsername && touched.userUsername ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.userUsername}
                </small>
              ) : null}
            </div>
            {/* Password */}
            <div className="flex flex-col justify-start space-y-3">
              <Field
                name="userPassword"
                id="userPassword"
                type="password"
                placeholder={PASSWORD}
                className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50"
              />
              {errors.userPassword && touched.userPassword ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.userPassword}
                </small>
              ) : null}
            </div>

            {/* Submit button */}
            <button
              type="submit"
              className="py-2  border rounded-lg  bg-white text-default "
            >
              {isLoading ? (
                 <ScaleLoader
                 height="25"
                 width="4"
                 radius="2"
                 margin="2"
                 color="#053359"
               />
              ) : (
                LOGIN
              )}
            </button>
          </Form>
        )}
      </Formik>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    isLoading: state.app.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
    resetLoader: (type) => dispatch(resetLoader(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
