import axios from "axios";


/// Api Definition
/* The code `export const instance = axios.create({ ... })` is creating a new instance of the Axios
library called `instance`. This instance is configured with a base URL of
"https://api.pharania.com/api/" and the `withCredentials` option set to `true`. This means that any
requests made using this instance will include credentials (such as cookies) when making
cross-origin requests. */
export const instance = axios.create({
  baseURL: "https://dev.api.pharania.com/api/",
 //  baseURL: "http://localhost:8000/api/",
  withCredentials: true,
});

/* The code `export const CSRFInstance = axios.create({ ... })` is creating a new instance of the Axios
library called `CSRFInstance`. This instance is configured with a base URL of
"https://api.pharania.com/sanctum/csrf-cookie". */
export const CSRFInstance = axios.create({
  baseURL: "https://dev.api.pharania.com/sanctum/csrf-cookie",
  // baseURL: "http://localhost:8000/sanctum/csrf-cookie",

});

/* The code `export const apiWithOutCredentials = axios.create({ ... })` is creating a new instance of
the Axios library called `apiWithOutCredentials`. This instance is configured with a base URL of
"https://api.pharania.com" and the `withCredentials` option set to `true`. */
export const apiWithOutCredentials = axios.create({
   baseURL: "https://dev.api.pharania.com",
  // baseURL: "http://localhost:8000",
  withCredentials: true,
});

/// Interceptor

/* The code `instance.interceptors.response.use(...)` is setting up an interceptor for the Axios
instance called `instance`. */

