import { Field, Form, Formik } from "formik";
import { Component } from "react";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import {
  EMAIL_DOESNOT_EXIST,
  EMAIL_FIELD,
  EMAIL_IS_REQUIRED,
  EMAIL_RESET_LINK_HAS_BEEN_SENT,
  SUBMIT_FORGOT_PASSWORD,
  WRONG_EMAIL_FORMAT,
} from "../../config/app_strings";
import { resetLoader } from "../../actions/AppAction";
import SnackbarMessage from "../SnackBarMessage";
import { SnackbarMessageType } from "../../enums/SnackBarMessageType";
import { connect } from "react-redux";
import {
  ForgotPassword,
  ResetPassword,
  forgotPassword,
} from "../../actions/AuthAction";
import { RESET_LOADER } from "../../actions/Type";

class ForgotPasswordform extends Component {
  componentWillUnmount() {
    this.props.resetLoader(RESET_LOADER);
  }

  render() {
    const {
      isForgotPasswordLoading,
      forgotPassword,
      isForgotPasswordSuccessful,
      forgotPasswordErrorMessage,
    } = this.props;

    const ForgotPasswordFormValidationSchema = Yup.object().shape({
      email: Yup.string().required(EMAIL_IS_REQUIRED).email(WRONG_EMAIL_FORMAT),
    });

    const submittingForgotPassword = async (values, resetForm) => {
      let content = {
        data: {
          attributes: {
            email: values.email,
          },
        },
      };

      await forgotPassword(content);
    };

    return (
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={ForgotPasswordFormValidationSchema}
        onSubmit={(values) => submittingForgotPassword(values)}
      >
        {({ errors, touched, values, resetForm }) => (
          <Form className="flex flex-col w-full px-5 pt-10 pb-5 space-y-4">
            <div className="flex flex-col justify-start space-y-3 w-full">
              <Field
                name="email"
                id="email"
                type="email"
                placeholder={EMAIL_FIELD}
                className="p-3 border rounded-lg placeholder-textColor font-extralight text-textColor bg-gray-50"
              />
              {errors.email && touched.email ? (
                <small className="text-sm text-left text-lightbg">
                  {errors.email}
                </small>
              ) : null}
            </div>

            {/* Submit button */}
            <button
              type="submit"
              className="py-2  border rounded-lg  bg-white text-default "
              disabled={isForgotPasswordLoading}
            >
              {isForgotPasswordLoading ? (
                <ClipLoader
                  size={30}
                  width="4"
                  radius="2"
                  margin="2"
                  color="#053359"
                />
              ) : (
                SUBMIT_FORGOT_PASSWORD
              )}
            </button>
            {isForgotPasswordSuccessful === true && (
              <SnackbarMessage
                type={SnackbarMessageType.SUCCESS}
                message={EMAIL_RESET_LINK_HAS_BEEN_SENT}
              />
            )}
            {isForgotPasswordSuccessful === false && (
              <SnackbarMessage
                type={SnackbarMessageType.ERROR}
                message={forgotPasswordErrorMessage}
              />
            )}
          </Form>
        )}
      </Formik>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    forgotPasswordErrorMessage: state.auth.resetError,
    isForgotPasswordLoading: state.app.isForgotPasswordLoading,
    isForgotPasswordSuccessful: state.auth.isForgotPasswordSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (content) => dispatch(forgotPassword(content)),
    resetLoader: (type) => dispatch(resetLoader(type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordform);
