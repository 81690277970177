import React, { useEffect, useState } from "react";
import ClimbingBoxLoader from "react-spinners/ClimbingBoxLoader";
import { Link, Navigate } from "react-router-dom";
import { RiAddFill, RiChatQuoteLine } from "react-icons/ri";
import { AgGridReact } from "ag-grid-react";
import { AiFillDelete, AiFillLock, AiOutlineSelect } from "react-icons/ai";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";

// Component imports
import SideNav from "../../../components/Nav/SideNav";
import {
  numberFormat,
  defaultColDef,
  rowSelectionType,
} from "../../../components/Utility/Variables";
import { instance } from "../../../config/api";
import {
  encryptAndDecryptLocalStorage,
  isUserStillLoggedIn,
} from "../../../actions/AuthAction";
import { UserDisplayOperation, UserOperation } from "../../../enums/user";
import {
  ADD_ADMIN_USER,
  ADD_ADMIN_USER_DESCRIPTION,
  ADMINS,
  ADMIN_MANAGEMENT_DESCRIPTION,
  ADMIN_USER_MANAGEMENT,
  CHOOSE_OPTION,
  CHOOSE_OPTION_TO_DISPLAY_SECTION,
  EMAIL,
  FULL_NAME,
  PERFORM_UTILITY_FUNCTIONS_ON_USERS,
  ROLE,
  SUMMARY,
  TOTAL_ADMINS_IN_STORE,
  TOTAL_USERS_IN_STORE,
  USERS,
  USER_MANAGEMENT,
  USER_MANAGEMENT_DESCRIPTION,
  UTILITY,
  DATE_OF_BIRTH,
  CREATED_AT,
  UPGRADING,
  UPGRADE_USER_TO_AUTHOR,
} from "../../../config/app_strings";
import { format } from "date-fns";
import ShimmerIndicator from "../../../components/Shimmer/ShimmerIndicator";
import { MoonLoader } from "react-spinners";
import { BiMessageSquareEdit } from "react-icons/bi";
import ActionModal from "../../../components/Modal/ActionModal";
import { connect } from "react-redux";
import {
  updgradeReader,
  upgradeReader,
} from "../../../actions/SupplementsAction";

function Root(props) {
  if (!isUserStillLoggedIn()) return <Navigate to={"/admin_root_login"} />;

  return (
    <div>
      <div className="flex-col md:flex md:flex-row md:min-h-screen bg-lightbg">
        <SideNav />
        <UserDashContent
          updgradeReader={props.upgradeReader}
          isReaderUpgradeSuccessful={props.isReaderUpgradeSuccessful}
        />
      </div>
    </div>
  );
}

const UserDashContent = ({
  deleteBlog,
  isLoading,
  updgradeReader,
  isReaderUpgradeSuccessful,
}) => {
  const [getUsers, setUsers] = useState([]);
  const [getReaders, setReaders] = useState([]);
  const [getAdmins, setAdmins] = useState([]);
  const [stateLoading, setStateLoading] = useState(false);

  useEffect(() => {
    async function fetchUser() {
      setStateLoading(true);
      await instance
        .get("users", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        })
        .then((response) => {
          setUsers(response.data.data);

          const admins = response.data.data.filter(
            (user) => user.data.meta.role.data.role_id === 1
          );
          setAdmins(admins);
          const readers = response.data.data.filter(
            (user) => user.data.meta.role.data.role_id === 2
          );
          setReaders(readers);
        })
        .catch((error) => {});
      setStateLoading(false);
    }

    fetchUser();
  }, [isReaderUpgradeSuccessful]);

  return !stateLoading ? (
    <div className="flex flex-row w-full text-left bg-lightbg">
      <UserLeftContent
        deleteBlog={deleteBlog}
        isLoading={isLoading}
        updgradeReader={updgradeReader}
      />
      <UserDashRightSide
        getAdmins={getAdmins.length}
        getReaders={getReaders.length}
      />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center min-h-screen m-auto">
      <MoonLoader size="50" color="#008fe7" />
    </div>
  );
};

const UserLeftContent = ({ updgradeReader }) => {
  const [toggleSelection, setToggleSelection] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedField, setSelectedFieldId] = useState();
  const [selectedDelletableFieldName, setSelectedFieldName] = useState();
  const [adminData, setAdminData] = useState([]);
  const [userData, setUserData] = useState([]);
  useEffect(() => {
    // Fetch data for admins
    async function fetchAdminData() {
      try {
        const response = await instance.get("users", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        });
        const admins = response.data.data.filter(
          (user) => user.data.meta.role.data.role_id === 1
        );
        setAdminData(admins);
      } catch (error) {
        console.error("Error fetching admin data:", error);
      }
    }

    // Fetch data for users
    async function fetchUserData() {
      try {
        const response = await instance.get("users", {
          headers: {
            Authorization: `Bearer ${encryptAndDecryptLocalStorage(
              localStorage.getItem("login_user_token"),
              false
            )}`,
          },
        });
        const users = response.data.data.filter(
          (user) => user.data.meta.role.data.role_id === 2
        );

        setUserData(users);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    if (toggleSelection === UserDisplayOperation.ADMIN) {
      fetchAdminData();
    } else if (toggleSelection === UserDisplayOperation.USER) {
      fetchUserData();
    } else {
      setAdminData([]);
      setUserData([]);
    }
  }, [toggleSelection]);
  const gridData =
    toggleSelection === UserDisplayOperation.ADMIN ? adminData : userData;

  const columnDefs = [
    { headerName: FULL_NAME, field: "data.attributes.name" },
    { headerName: EMAIL, field: "data.attributes.email" },
    {
      headerName: ROLE,
      cellRenderer: (params) => (
        <p className="capitalize">
          {params.data.data.meta.role.data.attributes.title}
        </p>
      ),
    },
    {
      headerName: DATE_OF_BIRTH,
      cellRenderer: (params) => {
        const dateOfBirth = params.data.data.attributes.date_of_birth;

        if (!dateOfBirth) {
          return <p>No data</p>;
        } else {
          const formattedDate = format(
            new Date(dateOfBirth),
            "EEEE MMM d, yyyy"
          );
          return <p>{formattedDate}</p>;
        }
      },
    },
    {
      headerName: CREATED_AT,
      cellRenderer: (params) => {
        const createdAt = params.data.data.attributes.created_at;

        if (!createdAt) {
          return <p>No data</p>;
        } else {
          const formattedDate = format(new Date(createdAt), "EEEE MMM d, yyyy");
          return <p>{formattedDate}</p>;
        }
      },
    },
    toggleSelection === UserDisplayOperation.USER
      ? {
          headerName: "Action",
          field: "id",
          filter: false,
          cellRenderer: (params) => (
            <div className="flex flex-row items-center space-x-5">
              <button
                className="text-lightDefault bg-transparent rounded-full text-base"
                onClick={() =>
                  openingModalElement(
                    params.data.data.user_id,

                    params.data.data.attributes.name
                  )
                }
              >
                {UPGRADE_USER_TO_AUTHOR}
              </button>
            </div>
          ),
        }
      : {},
  ];

  // opening modal
  const openingModalElement = (selectedField, selectedFieldName) => {
    setIsModalOpen(true);
    setSelectedFieldId(selectedField);
    setSelectedFieldName(selectedFieldName);
  };

  // closing Modal
  const closingModalElement = () => {
    setIsModalOpen(false);
    setSelectedFieldId(null);
    setSelectedFieldName("");
  };

  // onHandle toggle change selection
  const onHandleChangeSelection = (e) => {
    setToggleSelection(e.target.value);
  };

  return (
    <div className="w-5/6 max-h-screen overflow-x-auto">
      {/* User Header */}
      <div className="flex flex-row items-center justify-between px-5 py-10">
        <div className="flex flex-col ">
          <p className="text-4xl font-bold text-textColor">
            {toggleSelection === UserDisplayOperation.ADMIN
              ? ADMIN_USER_MANAGEMENT
              : toggleSelection === UserDisplayOperation.USER
              ? USER_MANAGEMENT
              : ""}
          </p>
          <p className="text-sm font-thin">
            {toggleSelection === UserDisplayOperation.ADMIN
              ? ADMIN_MANAGEMENT_DESCRIPTION
              : toggleSelection === UserDisplayOperation.USER
              ? USER_MANAGEMENT_DESCRIPTION
              : ""}
          </p>
        </div>

        <select
          id="toggleSelection"
          name="toggleSelection"
          className="w-1/4 px-3 py-3 bg-transparent border rounded-lg text-textColor"
          onChange={(e) => onHandleChangeSelection(e)}
        >
          <option value="">{CHOOSE_OPTION}</option>
          <option value={UserDisplayOperation.ADMIN}>{ADMINS}</option>
          <option value={UserDisplayOperation.USER}>{USERS}</option>
        </select>
      </div>

      {/* View User */}
      {toggleSelection === UserDisplayOperation.ADMIN ? (
        <div
          className="w-full text-black placeholder-black bg-transparent border ag-theme-material"
          style={{ height: "80vh", color: "black" }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={gridData}
            rowSelectionType={rowSelectionType}
            rowMultiSelectWithClick={true}
            pagination={true}
            className="text-left text-black placeholder-black bg-transparent"
          ></AgGridReact>
        </div>
      ) : toggleSelection === UserDisplayOperation.USER ? (
        <div
          className="w-full text-black placeholder-black bg-transparent border ag-theme-material"
          style={{ height: "80vh", color: "black" }}
        >
          <AgGridReact
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={gridData}
            rowSelectionType={rowSelectionType}
            rowMultiSelectWithClick={true}
            pagination={true}
            className="text-left text-black placeholder-black bg-transparent"
          ></AgGridReact>
          <ActionModal
            isUpgrading={true}
            fromName={""}
            actionFromName={UPGRADING}
            isModalOpen={isModalOpen}
            onClose={closingModalElement}
            onConfirm={async () => {
              updgradeReader(selectedField);
            }}
            itemName={selectedDelletableFieldName}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center my-20 space-y-6 text-center h-96">
          <AiOutlineSelect size="70" className="text-lightDefault" />
          <p>{CHOOSE_OPTION_TO_DISPLAY_SECTION}</p>
        </div>
      )}
    </div>
  );
};

// User Right Side
const UserDashRightSide = ({ getAdmins, getReaders }) => {
  return (
    <div className="w-1/3 max-h-screen px-10 overflow-y-auto bg-white shadow-2xl text-lightDefault">
      <div className="flex flex-col py-10">
        <p className="text-sm font-thin">
          {PERFORM_UTILITY_FUNCTIONS_ON_USERS}
        </p>
        <p className="text-4xl font-bold text-default">{UTILITY}</p>
      </div>

      {/* Add User */}
      <Link
        to={{
          pathname: "/users/create",
        }}
        state={{ selectedService: UserOperation.ADD_ADMIN }}
        className="flex flex-row items-start space-x-5"
      >
        <div className="p-3 bg-passOverColor rounded-xl">
          <RiAddFill size="25" className="text-white" />
        </div>
        <div className="text-lightDefault">
          <p className="font-bold">{ADD_ADMIN_USER}</p>
          <p className="text-sm text-default">{ADD_ADMIN_USER_DESCRIPTION}</p>
        </div>
      </Link>

      {/* Summary */}
      <div className="mt-8">
        <div className="flex flex-row items-center justify-between">
          <p className="text-lg font-bold text-lightDefault">{SUMMARY}</p>
        </div>

        {/* Each Notice */}
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">
              {numberFormat.format(getAdmins)}
            </p>
            <p className="text-sm text-default">{TOTAL_ADMINS_IN_STORE}</p>
          </div>
        </div>
        <div to={"..."} className="flex flex-row items-start mt-8 space-x-5">
          <div className="p-3 bg-passOverColor rounded-xl">
            <RiChatQuoteLine size="30" className="text-white" />
          </div>
          <div className="text-lightDefault">
            <p className="text-2xl font-bold">
              {numberFormat.format(getReaders)}
            </p>
            <p className="text-sm text-default">{TOTAL_USERS_IN_STORE}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isReaderUpgradeSuccessful: state.supplements.isReaderUpgradeSuccessful,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    upgradeReader: (user_id) => dispatch(upgradeReader(user_id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Root);
